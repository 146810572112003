body {
    background-color: #f7f0e6; /* Light cream */
    color: #3e3e3e; /* Dark charcoal */
    font-family: Arial, sans-serif; /* Use a clean, readable font */
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.cards__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.card {
    background-color: #fff; /* White background for cards */
    border: 1px solid #ddd; /* Light border */
    border-radius: 8px; /* Rounded corners */
    margin: 10px; /* Space between cards */
    padding: 15px; /* Padding inside cards */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    width: 100%; /* Full width on mobile */
}

.heading {
    text-align: center;
    color: #d9534f; /* Tomato red for headings */
    font-size: 1.5em; /* Adjusted font size for headings */
    margin-top: 20px;
    margin-bottom: 20px;
}

.button {
    background-color: #5cb85c; /* Fresh green */
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    margin-top: 10px;
    width: 100%; /* Full width button for mobile */
}

.button:hover {
    background-color: #4cae4c; /* Darker green on hover */
}

.cart__container span {
    color: #d9534f; /* Tomato red for total price */
    font-weight: bold; /* Bold text for total price */
}
