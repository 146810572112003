:root{
    --primary-color: #e0c521;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 130px;
    height: 235px;
    border: 2px solid var(--primary-color);
    margin: 13px 10px;
    position: relative;
    border-radius: 8px;
}

.card__title {
    font-weight: 400;
    text-align: center;
}

.card__title .card__price {
    font-weight: bold;
}

.image__container{
    width: 100px;
    height: 100px;
    margin: 0 auto;
}

.image__container img {
    width: 100%;
    padding-top: 10px;
    object-fit: cover;
}

.btn__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 10px;
}

.card__price {
    text-align: center;
    display: inline-block;
}

.card__badge {
    position: absolute;
    top: 0;
    right: 0;
    width: 25px;
    height: 25px;
    background-color: rgb(227, 112, 30);
    border-radius: 50%;
    transform: translate(20px, -20px) scale(1);
    color: white;
    font-weight: bold;
    text-align: center;
    border: 2px solid rgb(227, 112, 30);
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    animation: scaleanimation 1s ease infinite;
    opacity: 1;
}

.card__badge--hidden {
    display: none;
}

@keyframes scaleanimation {
 to {
    transform: translate(20px, -20px) scale(1.05);
    opacity: 1;
 }   
}