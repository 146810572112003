/* src/components/Footer/Footer.css */

.footer {
    background-color: #3e3e3e; /* Dark charcoal */
    color: white;
    padding: 20px 0;
    text-align: center;
}

.footer__content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer__links {
    list-style-type: none;
    padding: 0;
    margin: 10px 0 0;
    display: flex;
    justify-content: center;
    gap: 20px;
    font-size: small;
}

.footer__links li {
    display: inline;
}

.footer__links a {
    color: #5cb85c; /* Fresh green */
    text-decoration: none;
    font-weight: bold;
}

.footer__links a:hover {
    color: #4cae4c; /* Darker green on hover */
    text-decoration: underline;
}
